import React from 'react';

class CardBack extends React.Component {
    render() {
        return (
            <div className="CardBack" onClick={this.props.onClick}>
                <h4 className="subCategory"><div className="subCategoryText">{this.props.subCategory}</div></h4>
                <p className="Content">
                    {this.props.content}
                </p>
            </div>
        )
    }
}

export default CardBack;