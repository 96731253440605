import React from "react";
import { Button, Col, Container, Row } from 'react-bootstrap';
import Card from './Card';

const cards = [
    { deck: "Ideation", category: "Intentions", subCategory: "Big Picture", content: "How does this relate to campus?" },
    { deck: "Ideation", category: "Intentions", subCategory: "Big Picture", content: "What kind of problem is this? (Theological, Pastoral, Logistical, Organisational, etc)" },
    { deck: "Ideation", category: "Intentions", subCategory: "Big Picture", content: "Which Christian virtues are involved here?" },
    { deck: "Ideation", category: "Intentions", subCategory: "Big Picture", content: "What’s the core reasons behind this problem?" },
    { deck: "Ideation", category: "Intentions", subCategory: "Big Picture", content: "What problems bugs you? What are the internal causes?" },
    { deck: "Ideation", category: "Intentions", subCategory: "Big Picture", content: "Does the event produce an opportunity?" },
    { deck: "Ideation", category: "Intentions", subCategory: "Big Picture", content: "What bigger issues are you neglecting?" },
    { deck: "Ideation", category: "Intentions", subCategory: "Big Picture", content: "Imagine you solved this issue, what would you work on next?" },
    { deck: "Ideation", category: "Intentions", subCategory: "Big Picture", content: "Imagine you solved this issue or problem, what is the next area that CFers should work on?" },
    { deck: "Ideation", category: "Intentions", subCategory: "Big Picture", content: "What do you hear your friends/classmates say about this issue?" },
    { deck: "Ideation", category: "Intentions", subCategory: "Facts and Information", content: "Look through some of your CFers Instagram. What captures their interest and thoughts?" },
    { deck: "Ideation", category: "Intentions", subCategory: "Facts and Information", content: "How does location affect your purpose? Do problems or solution arise from a place?" },
    { deck: "Ideation", category: "Intentions", subCategory: "Facts and Information", content: "Any expert opinion or relevant research on this topic?" },
    { deck: "Ideation", category: "Intentions", subCategory: "Facts and Information", content: "Recall recent real-life occurrences of this issue. Does it shed any light?" },
    { deck: "Ideation", category: "Intentions", subCategory: "Feelings and Emotions", content: "What does each team member feel about this topic?" },
    { deck: "Ideation", category: "Intentions", subCategory: "Negatives", content: "Have many others tried to achieve this but fail? Why do you think so?" },
    { deck: "Ideation", category: "Intentions", subCategory: "Negatives", content: "Who, how and why does this issue hurt?" },
    { deck: "Ideation", category: "Intentions", subCategory: "Positives", content: "What are some positive changes that you want to see?" },
    { deck: "Ideation", category: "Refine", subCategory: "Big Picture", content: "Is the purpose too broad?" },
    { deck: "Ideation", category: "Refine", subCategory: "Big Picture", content: "What are the important priorities here?" },
    { deck: "Ideation", category: "Refine", subCategory: "Big Picture", content: "Is the problem being over-simplified?" },
    { deck: "Ideation", category: "Refine", subCategory: "Big Picture", content: "How does this relate to campus?" },
    { deck: "Ideation", category: "Refine", subCategory: "Big Picture", content: "What bible character/story that is linked to your purpose?" },
    { deck: "Ideation", category: "Refine", subCategory: "Facts and Information", content: "Any bible story that shared a similar context?" },
    { deck: "Ideation", category: "Refine", subCategory: "Facts and Information", content: "What is some information you need but do not have?" },
    { deck: "Ideation", category: "Refine", subCategory: "Facts and Information", content: "Think of the past few CF sessions, have they address this before? What could be better?" },
    { deck: "Ideation", category: "Refine", subCategory: "Feelings and Emotions", content: "What emotion feel about what you have planned so far? What is the core reason for that emotion?" },
    { deck: "Ideation", category: "Refine", subCategory: "Negatives", content: "Critique your own planned purpose/objectives." },
    { deck: "Ideation", category: "Refine", subCategory: "Negatives", content: "What are some worries you for what you have thought of so far?" },
    { deck: "Ideation", category: "Refine", subCategory: "Negatives", content: "What are some obstacles that prevents this purpose / objective to be achieved?" },
    { deck: "Ideation", category: "Refine", subCategory: "Positives", content: "What would people enjoy doing/learning this?" },
    { deck: "Ideation", category: "Refine", subCategory: "Positives", content: "What about what you have planned gives you hope?" },
    { deck: "Imagination", category: "Drafting", subCategory: "Mode", content: "Physical, online, hybrid - think of which setting best suits the delivery of your purpose." },
    { deck: "Imagination", category: "Drafting", subCategory: "Mode", content: "Lecture, panel, group discussion, game - think of how best to deliver your message." },
    { deck: "Imagination", category: "Drafting", subCategory: "Mode", content: "In a perfect world, how might you picture this event playing out?" },
    { deck: "Imagination", category: "Drafting", subCategory: "Mode", content: "List some boundaries you have to work within (e.g time, space, manpower)." },
    { deck: "Imagination", category: "Drafting", subCategory: "Safety", content: "List some challenges you can foresee in your plans. How can you circumvent these?" },
    { deck: "Imagination", category: "Drafting", subCategory: "Safety", content: "Imagine how your audience might react negatively to your plans." },
    { deck: "Imagination", category: "Drafting", subCategory: "Safety", content: "What might be some technical difficulties you might encounter in your plans?" },
    { deck: "Imagination", category: "Drafting", subCategory: "Safety", content: "How adaptable are your plans to sudden change?" },
    { deck: "Imagination", category: "Drafting", subCategory: "Lead-up", content: "How far in advance is a reasonable time to start registration?" },
    { deck: "Imagination", category: "Drafting", subCategory: "Lead-up", content: "When preparing the registration message, what might a newcomer need to know about the event?" },
    { deck: "Imagination", category: "Drafting", subCategory: "Lead-up", content: "What about your content entices your audience? How can you highlight that?" },
    { deck: "Imagination", category: "Drafting", subCategory: "Lead-up", content: "What information do you need from your audience before the event?" },
    { deck: "Imagination", category: "Revisiting", subCategory: "Program", content: "Look back at the purpose you have set for your event. Does your main program communicate that to your CFers?" },
    { deck: "Imagination", category: "Revisiting", subCategory: "Program", content: "Take this chance to look at your program with a fresh perspective. Is there a more interesting way to approach it?" },
    { deck: "Imagination", category: "Revisiting", subCategory: "Program", content: "Consider if your program is overdone. How might you make it unique?" },
    { deck: "Imagination", category: "Revisiting", subCategory: "Program", content: "Step into the shoes of your CFers. What might they take away from your program?" },
    { deck: "Imagination", category: "Revisiting", subCategory: "People", content: "Does the program demand attention from your audience?" },
    { deck: "Imagination", category: "Revisiting", subCategory: "People", content: "Does the program challenge your audience?" },
    { deck: "Imagination", category: "Revisiting", subCategory: "People", content: "Are there groups of people that may not receive the program well?" },
    { deck: "Imagination", category: "Revisiting", subCategory: "People", content: "Are there groups of people that may not have the resources for the activity?" },
    { deck: "Imagination", category: "Revisiting", subCategory: "Resource", content: "Are you working on a realistic timeline?" },
    { deck: "Imagination", category: "Revisiting", subCategory: "Resource", content: "Is your whole team satisfied and excited about the event?" },
    { deck: "Imagination", category: "Revisiting", subCategory: "Resource", content: "Write down a to-do list, listing them from most urgent to least, taking into consideration the time required for each item." },
    { deck: "Imagination", category: "Revisiting", subCategory: "Resource", content: "Has the team been challenged to think beyond the norm?" },
    { deck: "Imagination", category: "Planning", subCategory: "People", content: "Assign roles to each committee member for planning. Has the team explored different portfolio assignments?" },
    { deck: "Imagination", category: "Planning", subCategory: "People", content: "When planning for groups, consider the dynamics within each group." },
    { deck: "Imagination", category: "Planning", subCategory: "People", content: "How can we engage the help of the seniors? Establish the role of seniors and inform them before the session." },
    { deck: "Imagination", category: "Planning", subCategory: "Welcome", content: "Pay special attention to games! Not all games are fun to everyone and not all games bond people. Do not assume all games serve the same purpose!" },
    { deck: "Imagination", category: "Planning", subCategory: "Welcome", content: "Plan for a welcome party! Arrange for certain people to say hello! Welcome! when people turn-up." },
    { deck: "Imagination", category: "Planning", subCategory: "Welcome", content: "As people stream in, consider a simple activity to check in with and engage your CFers." },
    { deck: "Imagination", category: "Planning", subCategory: "Tools", content: "Prepare a list of games to play together and be clear of the aim of the segment (e.g. icebreaking, get to know, discussion)." },
    { deck: "Imagination", category: "Planning", subCategory: "Tools", content: "Have a run-sheet of your session. Play out each activity in your head and pay attention to how the members would perceive it." },
    { deck: "Imagination", category: "Planning", subCategory: "Tools", content: "Consider using collaborative and visual tools to provide some middle ground for everyone to contribute in the same way." },
    { deck: "Execution", category: "Starting the Meeting", subCategory: "Welcome & Arrival", content: "Greet CFers as they enter the Call!" },
    { deck: "Execution", category: "Starting the Meeting", subCategory: "Getting Comfortable", content: "Don’t get too affected if they still refuse to turn on their camera/mic as everyone has their own comfort levels of doing so." },
    { deck: "Execution", category: "Starting the Meeting", subCategory: "Getting Comfortable", content: "Use check-ins or icebreakers to aid in getting members to turn on their camera/mic many have them turned off." },
    { deck: "Execution", category: "During the Meeting", subCategory: "Managing Discussions", content: "Give members time to process their thoughts and ask them if they need more time!" },
    { deck: "Execution", category: "During the Meeting", subCategory: "Managing Discussions", content: "Don’t kanchiong and answer first to fill the ‘awkward silence’." },
    { deck: "Execution", category: "During the Meeting", subCategory: "Managing Discussions", content: "After asking questions, pause for members to think." },
    { deck: "Execution", category: "During the Meeting", subCategory: "Managing Discussions", content: "Ask open-ended questions (i.e., not yes/no questions).   " },
    { deck: "Execution", category: "During the Meeting", subCategory: "Managing Discussions", content: "Your members need to know you’re interested in what they are saying!" },
    { deck: "Execution", category: "During the Meeting", subCategory: "Managing Discussions", content: "Repeat yourself in asking questions; when online, it’s sometimes hard to follow." },
    { deck: "Execution", category: "During the Meeting", subCategory: "Managing Discussions", content: "Prepare follow-up questions to encourage conversation (with your staff, at BS Training)" },
    { deck: "Execution", category: "During the Meeting", subCategory: "Managing Discussions", content: "Summarise the responses! It might be hard, but it is helpful! E.g., From everyone's answer, it seems like we see that the verse is telling us..." },
    { deck: "Execution", category: "During the Meeting", subCategory: "Bouncing Discussions", content: "If someone is disruptive/over-critical, gently share with them after a session about making space for others." },
    { deck: "Execution", category: "During the Meeting", subCategory: "Bouncing Discussions", content: "Observe: How will you pay attention to overly quiet members?" },
    { deck: "Execution", category: "During the Meeting", subCategory: "Bouncing Discussions", content: "Don’t be afraid to talk to your comm about conflict in group discussions. Talk it out!" },
    { deck: "Execution", category: "During the Meeting", subCategory: "Bouncing Discussions", content: "Observe: what is the group dynamics like?" },
    { deck: "Execution", category: "During the Meeting", subCategory: "Bouncing Discussions", content: "Paraphrase what your members say and ask someone else to share their thoughts on that. E.g. What do you think of <person’s> response/opinion?" },
    { deck: "Execution", category: "During the Meeting", subCategory: "Communicating", content: "Use creative ways to have members respond! E.g., emojis, empathy map, memes, one-word" },
    { deck: "Execution", category: "During the Meeting", subCategory: "Communicating", content: "Use a 2-step response! Step 1) Respond with an initial thought/feeling for the question. Step 2) Encourage them to elaborate on their initial responses." },
    { deck: "Execution", category: "During the Meeting", subCategory: "Break Time!", content: "Remember to give breaks! There are various kinds of breaks you can give. E.g., stretch pauses, screen breaks, mental breaks." },
    { deck: "Execution", category: "During the Meeting", subCategory: "Break Time!", content: "When giving longer breaks, consider assigning them a task. E.g., Think of..., take a picture of..., drink a glass of water." },
    { deck: "Execution", category: "During the Meeting", subCategory: "Conversing", content: "When discussions go off-topic, try to link the topic back to discussion points instead of dismissing the topic." },
    { deck: "Execution", category: "During the Meeting", subCategory: "Conversing", content: "Don’t force members to speak or show their face if they refuse to." },
    { deck: "Execution", category: "During the Meeting", subCategory: "Conversing", content: "Try alternative ways to get them to participate! E.g., Could you help us find out..., can you summarise for us?" },
    { deck: "Execution", category: "During the Meeting", subCategory: "Conversing", content: "Utilise online tools to get them to respond and share. E.g., Zoom chats, Miro sticky notes." },
    { deck: "Execution", category: "Ending the Meeting", subCategory: "Ending Discussions", content: "Highlight key learning points from the discussion." },
    { deck: "Execution", category: "Ending the Meeting", subCategory: "Checking Out", content: "Remind members of their application question." },
    { deck: "Execution", category: "Ending the Meeting", subCategory: "Checking Out", content: "Ask for something that they would like to continue to think about!" },
    { deck: "Execution", category: "Ending the Meeting", subCategory: "Checking Out", content: "Get them to share their feelings/thoughts after the study. E.g., Using a word cloud for visualisation." },
];

class CardDeck extends React.Component {
    constructor() {
        super();
        this.state = {
            selectAll: true,
            random: Math.random()
        };
        this.clickAll = this.clickAll.bind(this);
        this.clickRandom = this.clickRandom.bind(this);
    }

    clickAll(e) {
        e.preventDefault();
        this.setState({ selectAll: true })
    }

    clickRandom(e) {
        e.preventDefault();
        this.setState({ selectAll: false, random: Math.random() })
    }

    render() {
        var deck = this.props.deck;
        var category = this.props.category;
        var subCategory = this.props.subCategory;
        var cardDeck = [];
        for (var i = 0; i < cards.length; i++) {
            if ((deck === "All" || deck === cards[i].deck) && (category === "All" || category === cards[i].category) && (subCategory === "All" || subCategory === cards[i].subCategory)) {
                cardDeck.push(<Col className="CardContainer" md="auto" key={i}><Card className="Card" {...cards[i]} /></Col>)
            }
        }
        var randomCard = cardDeck[Math.floor(this.state.random * cardDeck.length)]
        return <div style={{ width: "80%" }}>
            <Container>
                <Row className="justify-content-md-right">
                    <Col md={{ span: 2, offset: 7 }}><Button onClick={this.clickAll} variant="outline-warning">Show All</Button></Col>
                    <Col md="auto"><Button onClick={this.clickRandom} variant="outline-light">Generate Random Card</Button></Col>
                </Row>
                <h6 className="text-right">*Based on your selections above</h6>
                <Row className="justify-content-md-center">
                    {this.state.selectAll ? cardDeck : randomCard}
                </Row>
            </Container>
        </div >
    }
}

export default CardDeck;