import './App.css';
import { Col, Row } from 'react-bootstrap';
import React from 'react';
import CardDeck from './Card Deck';

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      deck: "All",
      category: "All",
      subCategory: "All"
    }
    this.selectDeck = this.selectDeck.bind(this);
    this.selectCategory = this.selectCategory.bind(this);
    this.selectSubCategory = this.selectSubCategory.bind(this);


  }
  selectDeck(e) {
    this.setState({
      deck: e.target.value,
      category: "All",
      subCategory: "All"
    })
    if (document.getElementById("category")) {
      document.getElementById("category").value = "All"
    }
    if (document.getElementById("subCategory")) {
      document.getElementById("subCategory").value = "All"
    }
  }
  selectCategory(e) {
    this.setState({
      category: e.target.value,
      subCategory: "All"
    })
    if (document.getElementById("subCategory")) {
      document.getElementById("subCategory").value = "All"
    }
  }
  selectSubCategory(e) {
    this.setState({ subCategory: e.target.value })
  }
  render() {
    var options = [<Col xs="auto" key="deck">
      <Row>
        <Col style={{ fontSize: "calc(10px + 1.5vmin)" }} sm="auto">Deck:</Col>
        <Col xs="auto" style={{ marginTop: "auto", marginBottom: "auto", paddingTop: "4px", fontSize: "calc(10px + 0.5vmin)" }}>
          <select name="deck" id="deck" onChange={this.selectDeck} defaultValue={this.state.deck}>
            <option value="All">All</option>
            <option value="Ideation">Ideation</option>
            <option value="Imagination">Imagination</option>
            <option value="Execution">Execution</option>
          </select>
        </Col>
      </Row>
    </Col>];

    switch (this.state.deck) {
      case "Ideation":
        options.push(<Col xs="auto" key="category">
          <Row>
            <Col style={{ fontSize: "calc(10px + 1.5vmin)" }} sm="auto">Category:</Col>
            <Col xs="auto" style={{ marginTop: "auto", marginBottom: "auto", paddingTop: "4px", fontSize: "calc(10px + 0.5vmin)" }}>
              <select name="category" id="category" onChange={this.selectCategory} defaultValue={this.state.category}>
                <option value="All">All</option>
                <option value="Intentions">Intentions</option>
                <option value="Refine">Refine</option>
              </select>
            </Col>
          </Row>
        </Col>)
        break;
      case "Imagination":
        options.push(<Col xs="auto" key="category">
          <Row>
            <Col style={{ fontSize: "calc(10px + 1.5vmin)" }} sm="auto">Category:</Col>
            <Col xs="auto" style={{ marginTop: "auto", marginBottom: "auto", paddingTop: "4px", fontSize: "calc(10px + 0.5vmin)" }}>
              <select name="category" id="category" onChange={this.selectCategory} defaultValue={this.state.category}>
                <option value="All">All</option>
                <option value="Drafting">Drafting</option>
                <option value="Revisiting">Revisiting</option>
                <option value="Planning">Planning</option>
              </select>
            </Col>
          </Row>
        </Col>)
        break;
      case "Execution":
        options.push(<Col xs="auto" key="category">
          <Row>
            <Col style={{ fontSize: "calc(10px + 1.5vmin)" }} sm="auto">Category:</Col>
            <Col xs="auto" style={{ marginTop: "auto", marginBottom: "auto", paddingTop: "4px", fontSize: "calc(10px + 0.5vmin)" }}>
              <select name="category" id="category" onChange={this.selectCategory} defaultValue={this.state.category}>
                <option value="All">All</option>
                <option value="Starting the Meeting">Starting the Meeting</option>
                <option value="During the Meeting">During the Meeting</option>
                <option value="Ending the Meeting">Ending the Meeting</option>
              </select>
            </Col>
          </Row>
        </Col>)
        break;
      default:
        break;
    }

    switch (this.state.category) {
      case "Intentions":
      case "Refine":
        options.push(<Col xs="auto" key="subCategory">
          <Row>
            <Col style={{ fontSize: "calc(10px + 1.5vmin)" }} sm="auto">Sub Category:</Col>
            <Col xs="auto" style={{ marginTop: "auto", marginBottom: "auto", paddingTop: "4px", fontSize: "calc(10px + 0.5vmin)" }}>
              <select name="subCategory" id="subCategory" onChange={this.selectSubCategory} defaultValue={this.state.subCategory}>
                <option value="All">All</option>
                <option value="Big Picture">Big Picture</option>
                <option value="Facts and Information">Facts and Information</option>
                <option value="Feelings and Emotions">Feelings and Emotions</option>
                <option value="Negatives">Negatives</option>
                <option value="Positives">Positives</option>
              </select>
            </Col>
          </Row>
        </Col>)
        break;

      case "Drafting":
        options.push(<Col xs="auto" key="subCategory">
          <Row>
            <Col style={{ fontSize: "calc(10px + 1.5vmin)" }} sm="auto">Sub Category:</Col>
            <Col xs="auto" style={{ marginTop: "auto", marginBottom: "auto", paddingTop: "4px", fontSize: "calc(10px + 0.5vmin)" }}>
              <select name="subCategory" id="subCategory" onChange={this.selectSubCategory} defaultValue={this.state.subCategory}>
                <option value="All">All</option>
                <option value="Mode">Mode</option>
                <option value="Safety">Safety</option>
                <option value="Lead-up">Lead-up</option>
              </select>
            </Col>
          </Row>
        </Col>)
        break;
      case "Revisiting":
        options.push(<Col xs="auto" key="subCategory">
          <Row>
            <Col style={{ fontSize: "calc(10px + 1.5vmin)" }} sm="auto">Sub Category:</Col>
            <Col xs="auto" style={{ marginTop: "auto", marginBottom: "auto", paddingTop: "4px", fontSize: "calc(10px + 0.5vmin)" }}>
              <select name="subCategory" id="subCategory" onChange={this.selectSubCategory} defaultValue={this.state.subCategory}>
                <option value="All">All</option>
                <option value="Program">Program</option>
                <option value="People">People</option>
                <option value="Resource">Resource</option>
              </select>
            </Col>
          </Row>
        </Col>)
        break;
      case "Planning":
        options.push(<Col xs="auto" key="subCategory">
          <Row>
            <Col style={{ fontSize: "calc(10px + 1.5vmin)" }} sm="auto">Sub Category:</Col>
            <Col xs="auto" style={{ marginTop: "auto", marginBottom: "auto", paddingTop: "4px", fontSize: "calc(10px + 0.5vmin)" }}>
              <select name="subCategory" id="subCategory" onChange={this.selectSubCategory} defaultValue={this.state.subCategory}>
                <option value="All">All</option>
                <option value="People">People</option>
                <option value="Welcome">Welcome</option>
                <option value="Tools">Tools</option>
              </select>
            </Col>
          </Row>
        </Col>)
        break;
      case "Starting the Meeting":
        options.push(<Col xs="auto" key="subCategory">
          <Row>
            <Col style={{ fontSize: "calc(10px + 1.5vmin)" }} sm="auto">Sub Category:</Col>
            <Col xs="auto" style={{ marginTop: "auto", marginBottom: "auto", paddingTop: "4px", fontSize: "calc(10px + 0.5vmin)" }}>
              <select name="subCategory" id="subCategory" onChange={this.selectSubCategory} defaultValue={this.state.subCategory}>
                <option value="All">All</option>
                <option value="Welcome & Arrival">Welcome & Arrival</option>
                <option value="Getting Comfortable">Getting Comfortable</option>
              </select>
            </Col>
          </Row>
        </Col>)
        break;
      case "During the Meeting":
        options.push(<Col xs="auto" key="subCategory">
          <Row>
            <Col style={{ fontSize: "calc(10px + 1.5vmin)" }} sm="auto">Sub Category:</Col>
            <Col xs="auto" style={{ marginTop: "auto", marginBottom: "auto", paddingTop: "4px", fontSize: "calc(10px + 0.5vmin)" }}>
              <select name="subCategory" id="subCategory" onChange={this.selectSubCategory} defaultValue={this.state.subCategory}>
                <option value="All">All</option>
                <option value="Managing Discussions">Managing Discussions</option>
                <option value="Bouncing Discussions">Bouncing Discussions</option>
                <option value="Communicating">Communicating</option>
                <option value="Break Time!">Break Time!</option>
                <option value="Conversing">Conversing</option>
              </select>
            </Col>
          </Row>
        </Col>)
        break;
      case "Ending the Meeting":
        options.push(<Col xs="auto" key="subCategory">
          <Row>
            <Col style={{ fontSize: "calc(10px + 1.5vmin)" }} sm="auto">Sub Category:</Col>
            <Col xs="auto" style={{ marginTop: "auto", marginBottom: "auto", paddingTop: "4px", fontSize: "calc(10px + 0.5vmin)" }}>
              <select name="subCategory" id="subCategory" onChange={this.selectSubCategory} defaultValue={this.state.subCategory}>
                <option value="All">All</option>
                <option value="Ending Discussions">Ending Discussions</option>
                <option value="Checking Out">Checking Out</option>
              </select>
            </Col>
          </Row>
        </Col>)
        break;
      default:
        break;
    }
    return (
      <div className="App">
        <header className="App-header">
          <h1>Planning Aid</h1>
          <Row style={{ width: "70%" }}>
            {options}
          </Row>
          <CardDeck {...this.state} />
          <div style={{ position: "relative", width: "60%", height: "18vmax" }}>
            <p className="Quote">
              KUIIL: WHY ARE YOU SO DISTRUSTFUL OF DROIDS?
              <br />
              MANDO: IT TRIED TO KILL HIM.
              <br />
              KUIIL: IT WAS PROGRAMMED TO DO SO. DROIDS ARE NOT GOOD OR BAD. THEY ARE NEUTRAL REFLECTIONS OF THOSE WHO IMPRINT THEM.
              <br />
              - MANDALORIAN EP6
            </p>
          </div>
        </header>
      </div >
    )
  }
}

export default App;
