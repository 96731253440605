import React from "react";
import ReactCardFlip from "react-card-flip";
import CardBack from "./Card Back";
import CardFront from "./Card Front";

class Card extends React.Component {
    constructor() {
        super();
        this.state = {
            isFlipped: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        e.preventDefault();
        if (!window.getSelection().toString()) {
            this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
        }
    }

    render() {
        return <div className="Card">
            <ReactCardFlip isFlipped={this.state.isFlipped}>
                <CardFront {...this.props} onClick={this.handleClick} />
                <CardBack {...this.props} onClick={this.handleClick} />
            </ReactCardFlip>
        </div >
    }
}

export default Card;