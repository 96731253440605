import React from 'react';

class CardFront extends React.Component {
    render() {
        return (
            <div className="CardFront" onClick={this.props.onClick}>
                <h3 className="Deck">{this.props.deck}</h3>
                <div style={{ width: "100%" }}>
                    <p className="Category">
                        {this.props.category}
                    </p>
                </div>
            </div>
        )
    }
}

export default CardFront;